
header {
    color: black;
    padding: 1rem;
    text-align: left;
  }
  
  header nav ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
  }
  
  header nav ul li {
    display: inline;
    margin: 0 1rem;
  }
  
  header nav ul li a {
    color: white;
    text-decoration: none;
  }

  .navigation-bar{
    display: flex;
    align-items: center;    /* Centers vertically */
    border-bottom: 2px solid black ;
  }
  
  .header-logo{
    height:33px;
    width:33px;
}