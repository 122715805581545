/* src/App.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #333;
}

footer {
  color: black;
  padding: 1rem;
  text-align: center;
}

.events-section {
  padding: 2rem;
  text-align: center;
}

.events-section h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.events-section ul {
  list-style: none;
  padding: 0;
}

.events-section ul li {
  margin: 0.5rem 0;
}
