/* src/styles/Hero.css */
.hero {
    padding: 2rem;
    background-color: #f4f4f4;
}
  
  .hero-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:50px;
  }
  
  .hero-text {
    text-align: left;
    max-width: 500px;
  }
  
  .hero-text h1 {
    font-family: 'Pacifico', sans-serif;
    font-size: 3.8rem;
    margin: 0.5rem 0;
  }
  
  .cta-description {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
  
  .cta-button {
    background-color: #333;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 4px;
    margin: 1rem 0;
  }
  
  .cta-button:hover {
    background-color: #555;
  }
  
  .disclaimer {
    font-size: 0.75rem;
    color: #777;
    margin: 0.5rem 0;
  }
  
  .hero-image img {
    width: 100%;
    max-width: 600px;
    height: auto;
    border-radius: 8px;
  }
  